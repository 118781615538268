import type { IMutationReport } from '@app-services/shopping-list/shopping-list.types'
import { axios } from '@app-bootstrap/axios.bootstrap'
import { endpoints } from '@app-config/backend.config'

declare global {
    interface Window {
        dataLayer: object[] // GTM
        _sqzl: object[] // SpotlerActivate
    }
}

// ------------------------------------------------------------------------------
// Data layer push events
// ------------------------------------------------------------------------------

/**
 * Pushes given data without further validation/transformation into the GTM data-layer.
 */
const pushGTMData = (data: object): void => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push(data)
}

const pushGTMInitialForEcommerce = (): void => {
    pushGTMData({ ecommerce: null })
}

const pushGTMDataWithEcommerceInit = (data: object): void => {
    pushGTMInitialForEcommerce()
    pushGTMData(data)
}

/**
 * Pushes given data without further validation/transformation into the SpotlerActivate data-layer.
 */
const pushSpotlerData = (data: object): void => {
    window._sqzl = window._sqzl || []
    window._sqzl.push(data)
}

// ------------------------------------------------------------------------------
// Data handlers
// ------------------------------------------------------------------------------

async function handleShoppingListMutation(mutationReport: IMutationReport): Promise<void> {
    const gtmParams = mutationReport.toJSONSerializable()

    const spotlerParams = {
        ...gtmParams,
        tracker_type: 'tracker--spotler',
    }

    const [responseGtm, responseSpotler] = await Promise.all([
        axios.get(endpoints.cart.trackingData, { params: gtmParams }),
        axios.get(endpoints.cart.trackingData, { params: spotlerParams }),
    ])

    pushGTMDataWithEcommerceInit(responseGtm.data)
    pushSpotlerData(responseSpotler.data)
}

async function handleGTMFavoriteMutation(buyableIdentifier: string, mutationEvent: string): Promise<void> {
    const [response] = await Promise.all([
        axios.get(endpoints.favorites.trackingData, {
            params: {
                buyable_identifier: buyableIdentifier,
                wishlist_mutation_event: mutationEvent,
            },
        }),
    ])

    pushGTMDataWithEcommerceInit(response.data)
}

async function handleGTMAddFromWishListToShoppingList(): Promise<void> {
    const [response] = await Promise.all([
        axios.get(endpoints.favorites.addFromWishListToShoppingList),
    ])

    pushGTMDataWithEcommerceInit(response.data)
}

function debugCheckout(url: string, body: string): void {
    fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body,
    }).catch((error) => {
        console.error('XHR logging fetch error:', error)
    })
}

export const Tracker = {
    handleGTMAddFromWishListToShoppingList,
    debugCheckout,
    handleShoppingListMutation,
    handleGTMFavoriteMutation,
    pushGTMData,
    pushGTMDataWithEcommerceInit,
    pushGTMInitialForEcommerce,
    pushSpotlerData,
}
